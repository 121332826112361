@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~@flaticon/flaticon-uicons/css/all/all";

@layer utilities {
  @variants responsive {
    .masonry {
      column-gap: 1.5em;
      column-count: 1;
    }
    .masonry-sm {
      column-gap: 1.5em;
      column-count: 2;
    }
    .masonry-md {
      column-gap: 1.5em;
      column-count: 3;
    }
    .masonry-lg {
      column-gap: 1.5em;
      column-count: 4;
    }
    .break-inside {
      break-inside: avoid;
    }
  }
}

article a {
  color: #006fc5;
}
article a:hover {
  text-decoration: underline;
}

article h3,
article h4 {
  font-weight: 600;
  margin: 10px 0;
}

article h2 {
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: rgb(220 38 38);
  margin: 10px 0;
}

article h3,
.h3 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
}
article ul {
  margin-bottom: 1.25rem;
}

.swiper {
  width: 100%;
  height: 650px;
}

@media only screen and (min-width: 1600px) {
  .swiper {
    height: 800px;
  }
}

.swiper .image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
}
.swiper .swiper-slide {
  display: flex;
  align-items: center;
}

.swiper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper .swiper-pagination-bullet-active {
  background: #00bbe3;
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 20px !important;
}

.banner .swiper-button-next,
.banner .swiper-button-prev {
  color: #fff;
  width: 40px;
  height: 40px;
}
.banner .swiper-button-next::after,
.banner .swiper-button-prev::after {
  font-size: 30px;
  display: none;
}
.banner .swiper-button-next {
  right: 20px;
}
.banner .swiper-button-prev {
  left: 20px;
}
main ul {
  padding-left: 1.5rem;
  list-style-type: disc;
  padding-bottom: 1.5rem;
}

main ul ul {
  padding-bottom: 0 !important;
}

@media only screen and (max-width: 575px) {
  .banner .swiper-button-next,
  .banner .swiper-button-prev {
    display: none;
  }
}
